table {
    width: 100%;
    background: white;
    border: solid 1px #dddddd;
    border-collapse: collapse;
    border-spacing: 0;
}

table tr th,
table tr td {
    padding: 0.5625em 0.625em;
    border: 1px solid #dddddd;
}

table tr.even,
table tr.alt,
table tr:nth-of-type(even) {
    background: #f9f9f9;
}

@media only screen and (max-width: 768px) {
    table.resp,
    .resp thead,
    .resp tbody,
    .resp tr,
    .resp th,
    .resp td,
    .resp caption {
        display: block;
    }

    table.resp {
        border: none
    }

    .resp thead tr {
        display: none;
    }

    .resp tbody tr {
        margin: 1em 0;
        border: 1px solid #dddddd;
    }

    .resp td {
        border: none;
        border-bottom: 1px solid #dddddd;
        position: relative;
        padding-left: 45%;
        text-align: left;
        min-height: 40px;
    }

    .resp tr td:last-child {
        border-bottom: 1px double #dddddd;
    }

    .resp tr:last-child td:last-child {
        border: none;
    }

    .resp td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
    }
}
