body {
    font-size: 1.1em;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.form-control-edited {
    width: 385px;
    padding: 3px;
}

@media only screen and (max-width: 576px) {
    .form-control-edited {
        width: 300px;
        padding: 3px;
    }
}

input[type="number"] {
    width: 70px;
}

.navbar-dark {
    background-color: #000000;
    border-bottom: 3px solid #007bff;
}

.navbar-dark .navbar-nav .nav-link, .nav-sidebar .nav-item > .nav-link {
    color: white;
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
    height: auto;
}

.sidebar-dark-primary {
    background-color: #000000;
}

[class*=sidebar-dark] .brand-link {
    color: white;
}

.content-wrapper {
    background-color: #ededed;
}

@media (max-width: 767.98px) {
    .small-box .icon {
        display: flex;
    }

    .small-box p {
        font-size: 1rem;
    }
}