.grid-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

.grid-item {
    display: flex;
    border: 2px solid #ccc;
    border-radius: 16px;
    padding: 10px;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}