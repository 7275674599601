.contenedor {
    display: flex;
    align-items: center;
}

.linea {
    flex-grow: 1;
    height: 1px;
    background-color: #ccc;
}

.texto {
    padding: 0 10px;
}