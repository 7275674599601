@media only screen and (max-width: 768px) {

    .colComandes tr td:nth-of-type(1):before {
        content: "Comuner";
    }

    .colComandes tr td:nth-of-type(2):before {
        content: "Tipus de comanda";
    }

    .colComandes tr td:nth-of-type(3):before {
        content: "Presa";
    }

    .colComandes tr td:nth-of-type(4):before {
        content: "Hores";
    }

    .colComandes tr td:nth-of-type(5):before {
        content: "Horari";
    }

    .colComandes tr td:nth-of-type(6):before {
        content: "";
    }
}

@media only screen and (min-width: 768px) {
    .centre {
        text-align: center
    }
}
