@media only screen and (max-width: 768px) {

    .colCertificats tr td:nth-of-type(1):before {
        content: "Data";
    }

    .colCertificats tr td:nth-of-type(2):before {
        content: "Tipus";
    }

    .colCertificats tr td:nth-of-type(3):before {
        content: "Comuner";
    }

    .colCertificats tr td:nth-of-type(4):before {
        content: "Descarregar";
    }
}
